// src/styles/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';
import { useContext } from 'react';
import { LocaleContext } from '../utils/useTranslation';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'VIP Hakm Thin';
    src: url('/fonts/VIPHakmThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'VIP Hakm Bold';
    src: url('/fonts/VIPHakmBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'VIP Hakm Regular';
    src: url('/fonts/VIPHakmRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'VIP Hakm Regular', sans-serif;
  }
  body {
    font-family: 'VIP Hakm Regular', sans-serif;
    direction: ${({ locale }) => (locale === 'ar' ? 'rtl' : 'ltr')};
    text-align: ${({ locale }) => (locale === 'ar' ? 'right' : 'left')};
  }
  .ant-btn {
    margin: 0 0.5em;
  }
`;

const GlobalStyleWrapper = () => {
  const { locale } = useContext(LocaleContext);

  return <GlobalStyle locale={locale} />;
};

export default GlobalStyleWrapper;
