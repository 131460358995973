import React, { useContext, createContext } from 'react';
import { IntlProvider, useIntl } from 'react-intl';

const LocaleContext = createContext();

const useTranslation = () => {
  const intl = useIntl();
  return (messageId, defaultMessage) => {
    return intl.formatMessage({ id: messageId, defaultMessage });
  };
};

export { useTranslation, LocaleContext, IntlProvider };
