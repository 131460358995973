import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import Spinner from './Spinner';
import { Spin } from 'antd';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, login, loading } = useAuth();

  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (token) {
      login(token); // Update the auth state if token exists
    }
  }, [login]);

  if (loading) {
    return <Spinner />; // Show a loading indicator while checking authentication
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
