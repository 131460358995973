// src/components/NavMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HomeOutlined,
  FileTextOutlined,
  BarChartOutlined,
  AppstoreOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from '../utils/useTranslation';

const StyledMenu = styled(Menu)`
  height: 100vh;
  position: fixed;
  border: 1px solid rgba(0,0,0,0.15);
  ${({ position }) => (position === 'left' ? 'left: 0;' : 'right: 0;')}

  .ant-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
`;

const NavMenu = ({ position }) => {
  const translate = useTranslation();

  return (
    <StyledMenu mode="vertical" theme="light" position={position}>
      <Menu.Item key="1" icon={<HomeOutlined />}>
        <Link to="/home">{translate('m.home', 'Home')}</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<FileTextOutlined />}>
        <Link to="/orders">{translate('m.orders', 'Orders')}</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<BarChartOutlined />}>
        <Link to="/reports">{translate('m.reports', 'Reports')}</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<AppstoreOutlined />}>
        <Link to="/billboards">{translate('m.billboards', 'Billboards')}</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<UserOutlined />}>
        <Link to="/clients">{translate('m.clients', 'Clients')}</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<SettingOutlined />}>
        <Link to="/accounts">{translate('m.accounts', 'Accounts')}</Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<LogoutOutlined />}>
        <Link to="/logout">{translate('m.logout', 'Logout')}</Link>
      </Menu.Item>
    </StyledMenu>
  );
};

export default NavMenu;
