// src/slices/index.js
import { combineReducers } from 'redux';
import sampleReducer from './sampleSlice';
import authReducer from './authSlice';

const rootReducer = combineReducers({
  sample: sampleReducer,
  auth: authReducer,
});

export default rootReducer;
