import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainTemplate from './pages/MainTemplate';
import Spinner from './components/Spinner';
import { IntlProvider } from 'react-intl';
import { LocaleContext } from './utils/useTranslation';
import { messages } from './messages';
import GlobalStyle from './styles/GlobalStyle';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

// Lazy load the pages
const Login = lazy(() => import('./pages/Login'));
const FirstTimeRegister = lazy(() => import('./pages/FirstTimeRegister'));
const Orders = lazy(() => import('./pages/Orders'));
const Home = lazy(() => import('./pages/Home'));
const Reports = lazy(() => import('./pages/Reports'));
const Billboards = lazy(() => import('./pages/Billboards'));
const Clients = lazy(() => import('./pages/Clients'));
const Test = lazy(() => import('./pages/Test'));
const Welcome = lazy(() => import('./pages/Welcome'));
const ClientProfile = lazy(() => import('./pages/ClientProfile'));
const Accounts = lazy(() => import('./pages/Accounts'));
const Logout = lazy(() => import('./pages/Logout'));

const preload = () => {
  import('./pages/Home');
  import('./pages/Orders');
};

function App() {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en'); // Get the locale from localStorage or default to 'en'

  useEffect(() => {
    preload();
  }, []);

  useEffect(() => {
    localStorage.setItem('locale', locale); // Store the selected locale in localStorage
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <div className="App">
          <GlobalStyle />
          <AuthProvider>
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/first-time" element={<FirstTimeRegister/>} />
                <Route path="/test" element={<Test />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="*" element={
                  <ProtectedRoute>
                    <MainTemplate navPosition="left">
                      <Routes>
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/billboards" element={<Billboards />} />
                        <Route path="/clients" element={<Clients />} />
                        <Route path="/test" element={<Test />} />
                        <Route path="/welcome" element={<Welcome />} />
                        <Route path="/view-client" element={<ClientProfile />} />
                        <Route path="/accounts" element={<Accounts />} />
                        <Route path="*" element={<Home />} />
                      </Routes>
                    </MainTemplate>
                  </ProtectedRoute>
                } />
              </Routes>
            </Suspense>
          </AuthProvider>
        </div>
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

export default App;
