// src/components/MainTemplate.js
import React from 'react';
import styled from 'styled-components';
import NavMenu from '../components/NavMenu';

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  margin-left: ${({ position }) => (position === 'left' ? '200px' : '0')};
  margin-right: ${({ position }) => (position === 'right' ? '200px' : '0')};
  padding: 20px;
`;

const MainTemplate = ({ children, navPosition }) => (
  <MainContainer>
    <NavMenu position={navPosition} />
    <ContentContainer position={navPosition}>
      {children}
    </ContentContainer>
  </MainContainer>
);

export default MainTemplate;
