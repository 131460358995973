// src/components/Spinner.js
import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Spinner = () => (
  <SpinnerContainer>
    <Spin size="large" />
  </SpinnerContainer>
);

export default Spinner;
